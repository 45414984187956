/* Font Families */
$decorative-font: 'Lobster', cursive;
$text-font: 'Montserrat', sans-serif;

/* Font Sizes */
$font-xlarge: 5rem;
$font-large: 3rem;
$font-medium: 2rem;
$font-small: 1rem;
$font-xsmall: 0.5rem;

/* Line Weight */
$font-normal: 400;
$font-thin: 300;

/* Line Height */
$base-line-height: 1rem;
$spaced-line-height: 1.5rem;

/* Preset Fonts */

@mixin font(
    $font-size: $font-medium,
    $font: $text-fnont,
    $font-weight: $font-normal,
    $line-height: $base-line-height
) {
    font-family: $font;
    font-size: $font-size;
    font-weight: $font-weight;
    line-height: $line-height;
}

@mixin decorative {
    @include font(
        $font-xlarge,
        $decorative-font,
        $font-normal,
        calc($font-xlarge + $spaced-line-height)
    );
}


@mixin text {
    @include font(
        $font-medium,
        $text-font,
        $font-thin,
        calc($font-medium + $base-line-height)
    );
}