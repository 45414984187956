@import "/src/assets/styles/app.scss";

.shift-by-type-counter {
    &__counter {
        @include respond() {
            max-width: 33vw;

            p {
                @include font(
                    $font-small,
                    $text-font,
                    $font-thin,
                    calc($font-small + $base-line-height)
                );

                &.-decorative {
                    @include font(
                        $font-large,
                        $decorative-font,
                        $font-normal,
                        calc($font-large + $spaced-line-height)
                    );
                }
            }
        }
    }
}