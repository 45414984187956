@import "/src/assets/styles/app.scss";

.rainbow-text {
    @include respond {
        font-size: $font-large !important;
        line-height: $font-xlarge !important;
    }

    &__letter {
        margin-right: 0.25rem;

        &:last-of-type {
            margin-right: 0;
        }

        &.-blue {
            color: $special-blue;
        }

        &.-green {
            color: $special-green;
        }

        &.-yellow {
            color: $special-yellow;
        }
    }
}