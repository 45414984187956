@import "/src/assets/styles/app.scss";

.paragraph {
    @include text;

    &.-decorative {
        @include decorative;
    }

    &.-blue {
        color: $special-blue;
    }

    &.-green {
        color: $special-green;
    }

    &.-yellow {
        color: $special-yellow;
    }
}