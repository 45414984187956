.flex {
    display: flex;

    &.-align-items {
        &__center {
            align-items: center;
        }
    }

    &.-flex-direction {
        &__column {
            flex-direction: column;
        }

        &__row {
            flex-direction: row;
        }
    }

    &.-flex-wrap {
        &__no-wrap {
            flex-wrap: nowrap;
        }

        &__wrap {
            flex-wrap: wrap;
        }
    }

    &.-justify-content {
        &__center {
            justify-content: center;
        }

        &__end {
            justify-content: end;
        }

        &__start {
            justify-content: start;
        }

    }
}